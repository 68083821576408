import React from 'react';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import Layout from '../components/layout';

const OrderNowPage = () => (
  <Layout title="Order Now">
    <Hero backgroundImage="zoo-pharm" headline="Order Now" tagline="" />
    <section className="content-wrapper">
      <Grid justifyContent="center" alignItems="center">
        <GridItem md={8}>
          <h2>Welcome to ZooPharm!</h2>
          <h3>Our formulations may be ordered following the simple steps below:</h3>
          <ol style={{ marginBottom: 50 }}>
            <li>
              A veterinarian must first submit a patient-specific prescription for the formulation:
              <ul style={{ marginTop: 10 }}>
                <li>
                  Note one species/patient per prescription as required by your state Board of
                  Pharmacy, as well as a
                </li>
                <li>
                  Copy of the prescriber&apos;s current DEA &quot;Practitioner&quot; Controlled
                  Substance Certificate.
                </li>
                <li>
                  If your state allows for hospital or office use, please note this as opposed to
                  specific patient information.
                </li>
                <li>
                  Please download our
                  {' '}
                  <a
                    href="https://www.zoopharm.com/static/zoopharm_prescription_form_2020-1201ed7ea3d503461ae559527372e4c5.pdf"
                    target="blank"
                  >
                    PRESCRIPTION FORM
                  </a>
                  {' '}
                  here.
                </li>
              </ul>
            </li>
            <li style={{ marginTop: 15 }}>
              <p style={{ marginBottom: 10 }}>
                Please call ZooPharm Customer Service at
                {' '}
                <strong>(866) 823-9314</strong>
                {' '}
                approximately four hours post submission to set up your ZooPharm account.
              </p>
              <p>
                <em>
                  Please note, ZooPharm terms are prepaid check, ACH or credit card. We do not
                  accept purchase orders or offer net billing terms.
                </em>
              </p>
            </li>
          </ol>
          <div style={{ fontSize: '1.2em', textAlign: 'center' }}>
            <p style={{ marginBottom: 5 }}>
              If you have any questions or require additional information,
              {' '}
              <strong>please call</strong>
            </p>
            <p style={{ margin: 5 }}>
              <strong>ZooPharm Customer Service at (866) 823-9314.</strong>
            </p>
            <p style={{ marginBottom: 50 }}>
              You can reach us from 8:00 - 5:00 MST, Monday through Friday.
            </p>
            <p>
              <strong>Veterinary Prescription Submission FAX Line: (307) 761-6060</strong>
            </p>
          </div>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default OrderNowPage;
